.yagaan_desvger {
    clear: both;
    display: block;
    margin: 20px 0px;
    padding: 8px 15px;
    border-radius: 5px;
    background: #F9F4EC;
    font-size: 18px;
    font-weight: bold;
    color: #333;
}
